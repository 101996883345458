<template>
  <div class="flex flex-col gap-4 w-full">
    <c-card class="w-full flex flex-col p-5 pt-11 gap-0">
      <div class="grid grid-cols-3 gap-3">
        <div class="flex justify-start items-center gap-3 p-3 border border-cultured rounded-md">
          <c-icon icon-name="icon-clockin" size="s" style="fill:#F15A29" />
          <div class="flex flex-col justify-center items-start gap-0">
            <h3 class="text-xl font-bold text-jet">{{ analytics.clockInDays?.toFixed(0) || 0 }}</h3>
            <p class="text-xs font-black text-romanSilver uppercase">Clocked In (Days)</p>
          </div>
        </div>
        <div class="flex justify-start items-center gap-3 p-3 border border-cultured rounded-md">
          <c-icon icon-name="icon-clockin" size="s" style="fill:#2176FF" />
          <div class="flex flex-col justify-center items-start gap-0">
            <h3 class="text-xl font-bold text-jet">{{ analytics.clockInHours?.toFixed(0) || 0 }}</h3>
            <p class="text-xs font-black text-romanSilver uppercase">Clocked In (Hours)</p>
          </div>
        </div>
        <div class="flex justify-start items-center gap-3 p-3 border border-cultured rounded-md">
          <c-icon icon-name="clock-minus" class="text-blueCrayola" size="s" />
          <div class="flex flex-col justify-center items-start gap-0">
            <h3 class="text-xl font-bold text-jet">{{ analytics.absence || 0 }}</h3>
            <p class="text-xs font-black text-romanSilver uppercase">Absence (Days/Hrs)</p>
          </div>
        </div>
      </div>
      <CardFooter
        @actionModal="isOpenFilter = true"
        @sortType="$queryBuilder({ sort: $event }, getEssClockInOut)"
        @searchResult="$queryBuilder({ search: $event }, getEssClockInOut)"
      >
        <template v-slot:rightItems>
          <div class="ml-3 flex justify-start items-center gap-0">
            <c-icon icon-name="sort_size" class="cursor-pointer" size="xs" />
            <c-select
              :options="filterByWeekOptions"
              placeholder="Filter By"
              class="select-class"
              @input="$queryBuilder({ period: $event }, getEssClockInOut)"
              />
          </div>
        </template>
      </CardFooter>
    </c-card>

    <c-table
      :headers="headers"
      :items="clockInOutOptions"
      aria-label="ClockInOut Table"
      :loading="isFetching"
      :has-number="false"
      :page-sync="true"
      :pagination-list="clockInOutMeta"
      @page="$queryBuilder({ page: $event }, getEssClockInOut)"
      @itemsPerPage="$queryBuilder({ perPage: $event }, getEssClockInOut)"
      v-if="clockInOutOptions.length || isFetching"
    >
      <template v-slot:item="{ item }">
        <div v-if="item.date" class="flex justify-start items-start gap-2">
          {{ formatDate(item.data.date) }}
          <div>
          </div>
        </div>
        <span v-if="item.employeeOffice" class="text-darkPurple text-sm">
          {{ item.data.employeeOffice.name }}
        </span>
        <div v-if="item.clockIn" class="text-darkPurple text-sm">
          <span v-if="!item.data.clockIn || isNaN(new Date(item.data.clockIn).getTime())">-</span>
          <span v-else>{{ $DATEFORMAT(new Date(item.data.clockIn), 'hh:mm a') }}</span>
        </div>
        <div v-if="item.clockOut" class="text-darkPurple text-sm">
          <span v-if="!item.data.clockOut || isNaN(new Date(item.data.clockOut).getTime())">-</span>
          <span v-else>{{ $DATEFORMAT(new Date(item.data.clockOut), 'hh:mm a') }}</span>
        </div>
        <div v-if="item.availability" class="text-darkPurple text-sm">
          <span v-if="!item.data.availability">-</span>
          <span v-else>{{ item.data.availability }}</span>
        </div>
        <div v-if="item.status" class="rounded-md p-2 text-sm w-28 text-center capitalize"
          :class="{
            'bg-green-100 text-mediumSeaGreen': item.data.status === 'clocked in',
            'bg-red-100 text-flame': item.data.status === 'clocked out',
          }">
          {{ item.data.status }}
        </div>
        <c-menu v-if="item.id && !$route.params.id" left @click.stop="">
          <template v-slot:title>
            <c-icon icon-name="more_icon" size="xs" />
          </template>
          <div class="flex flex-col w-44 h-18 p-2 justify-start items-start">
            <div class="w-full" @click="onSubmitClockInOut(item.data, 'Clock In')">
              <div class="hover:bg-ghostWhite w-full rounded-md">
                <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                  <c-icon icon-name="icon-clockin" size="s" style="fill:#F15A29" />
                  <span class="text-darkPurple text-sm leading-5 font-normal">Clock In</span>
                </div>
              </div>
            </div>
            <div class="w-full" @click="onSubmitClockInOut(item.data, 'Clock Out')">
              <div class="hover:bg-ghostWhite w-full rounded-md">
                <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                  <c-icon icon-name="icon-clockin" size="s" style="fill:#2176FF" />
                  <span class="text-darkPurple text-sm leading-5 font-normal">Clock Out</span>
                </div>
              </div>
            </div>
          </div>
        </c-menu>
      </template>
    </c-table>

    <div v-else class="flex flex-col justify-center items-center gap-0 px-10">
      <c-icon icon-name="paygrade_Illustration" size="" class-name="w-56 h-56" />
      <p class="font-normal text-base leading-5 text-jet text-center w-1/2">
        Time tracking clockings can be found here. This is empty now,
        but you can start adding time tracking locations to populate this area.
      </p>
    </div>

    <c-modal v-if="isOpenClockInOut">
      <c-card class="flex flex-col gap-5 w-96 py-3 px-5">
        <p class="text-jet text-base font-bold border-b border-solid border-romanSilver">Attention Required!</p>
        <div class="flex flex-col justify-start items-start gap-0">
          <p class="text-jet text-base font-semibold">Are you sure you want to {{ action }}?</p>
          <p class="text-blueCrayola font-bold text-sm">Your {{ action }} time is {{ $DATEFORMAT(new Date(), 'HH:mm a') }}</p>
        </div>
        <div class="w-full flex justify-center gap-4 whitespace-nowrap">
          <c-button
            label="Cancel"
            variant="secondary c-button"
            :disabled="isLoadingClockInOut"
            @onClick="isOpenClockInOut = false"
          />
          <c-button
            variant="primary btn-bg c-button"
            :disabled="isLoadingClockInOut"
            :label="isLoadingClockInOut ? 'Please wait...' : action"
            @onClick="onSubmitClockInOut(userInfo, action)"
          />
        </div>
      </c-card>
    </c-modal>

    <FilterTable
      v-if="isOpenFilter"
      :filter-data="filterOptions"
      @close="isOpenFilter = false"
      @applyFilter="onSubmitFilter($event)"
    />
  </div>
</template>

<script>
import { camelCase } from "lodash"
import CButton from "@scelloo/cloudenly-ui/src/components/button"
import CTable from "@scelloo/cloudenly-ui/src/components/table"
import CCard from "@scelloo/cloudenly-ui/src/components/card"
import CMenu from "@scelloo/cloudenly-ui/src/components/Menu"
import CSelect from "@scelloo/cloudenly-ui/src/components/select"
import CIcon from "@/components/Icon"
import CardFooter from "@/components/CardFooter"
import FilterTable from "@/components/FilterTable"
import CModal from "@/components/Modal"

export default {
  components: {
    CButton,
    CMenu,
    CCard,
    CIcon,
    CSelect,
    CardFooter,
    CTable,
    FilterTable,
    CModal
  },
  computed: {
    filterOptions() {
      return [
        { header: 'Location', value: 'location', optionsBox: this.locationOptions },
        { header: 'Function', value: 'function', optionsBox: [] },
        { header: 'Designation', value: 'designation', optionsBox: [] },
        { header: 'Job Level', value: 'jobLevel', optionsBox: [] },
        { header: 'Employment Type', value: 'employmentType', optionsBox: [] },
        { header: 'Status', value: 'status', optionsBox: this.statusOptions },
      ]
    },
  },
  data() {
    return {
      isOpenFilter: false,
      isFetching: true,
      isSubmitting: false,
      isOpenClockInOut: false,
      isLoadingClockInOut: false,
      analytics: {},
      clockInOutOptions: [],
      clockInOutMeta: {},
      locationOptions: [],
      selectedLocation: {},
      action: 'Clock In',
      userInfo: {},
      latitude: null,
      longitude: null,

      headers: [
        { title: "Date", value: "date" },
        { title: "Location", value: "employeeOffice" },
        { title: "Clock In", value: "clockIn" },
        { title: "Clock Out", value: "clockOut" },
        { title: "Availability", value: "availability" },
        { title: "Status", value: "status" },
      ],
      statusOptions: [
        { name: 'Active', id: 'active' },
        { name: 'Inactive', id: 'inactive' }
      ],
      filterByWeekOptions: [
        { name: 'All', id: 'all' },
        { name: 'Today', id: 'today' },
        { name: 'This Week', id: 'this_week' },
        { name: 'This Month', id: 'this_month' },
        { name: 'Custom', id: 'custom' },
      ],

      payload: {
        locationIds: [],
        address: null,
        clockInRadius: null,
        clockOutRadius: null
      }
    };
  },
  methods: {
    formatDate(date) {
      const utcDate = new Date(`${date}T00:00:00Z`);

      const utcDateString = utcDate.toISOString();

      return this.$DATEFORMAT(new Date(utcDateString), "MMMM dd, yyyy");
    },

    onSubmitFilter(options){
      this.$queryBuilder(options, this.getEssClockInOut)
      this.isOpenFilter = false
    },
    onSubmitClockInOut(userInfo, action) {
      if (!this.isOpenClockInOut) {
        this.action = action
        this.userInfo = userInfo
        this.isOpenClockInOut = true
        return
      }

      this.isLoadingClockInOut = true
      const payload = {
        payload: {
          orgId: this.$orgId,
          date: userInfo.date,
          action: camelCase(action),
          userId: userInfo.employeeInfo.userId,
          latitude: this.latitude,
          longitude: this.longitude,
        },
        userId: this.$route.params.id || this.$AuthUser.id
      }

      this.$_postEssClockInOut(payload).then(({ data }) => {
        this.$toasted.success(data.message, { duration: 5000 })
        this.isLoadingClockInOut = false
        this.isOpenClockInOut = false
        this.getEssClockInOut({ month: 9, year: 2023 })
      }).catch((error) => {
        this.$toasted.error(error, { duration: 5000 })
        this.isLoadingClockInOut = false
      })
    },
    async getEssClockInOut(params) {
      this.isFetching = true
      const payload = { params, userId: this.$route.params.id || this.$AuthUser.id }
      await this.$_getEssClockInOut(payload).then(({ data }) => {
        this.analytics = data.analytics
        this.clockInOutOptions = data.clockingData.map((item) => ({
          ...item,
          employeeOffice: item.employeeInfo.employeeOffice,
          id: item.employeeInfo.userLevel.id
        }))
        this.clockInOutMeta = data.meta
        this.isFetching = false
      })
    },

    async bootstrapModule(){
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
        });
      }

      const selectedDate = new Date()

      const queryParams = {
        month: selectedDate.getMonth() + 1,
        year: selectedDate.getFullYear(),
      }

      await this.$queryBuilder(queryParams, this.getEssClockInOut)
    }
  },
  async created() {
    this.bootstrapModule()
  }
};
</script>

<style scoped>
::v-deep .select-class span select {
  margin-top: 3px !important;
  height: 42px !important;
  border: 0 !important;
}
.button-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  line-height: 120% !important;
  padding: 10px 50px !important;
}
.c-button {
  width: 100% !important;
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }
</style>